<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-2xl mb-2">Escaneo</h3>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-2 gap-4 mt-4" v-if="isToggle">
              <select-input
              idInput="awbSelect"
              idFormGroup="awb-group"
              label="AWBs"
              v-model="filterForm.awb"
              :options="awbListOptions"
              />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Validación</label>
      </div>
      <table-scanner-awb />
    </section>
  </div>
</template>

<script>
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TableScannerAwb from '../../components/TableScannerAwb.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'Scanner',
  components: {
    PlusIcon, MinusIcon, SelectInput, TableScannerAwb, BreadCrumbs,
  },
  data() {
    return {
      isToggle: true,
      filterForm: {
        awb: null,
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      awbListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: '992-17754800', value: '992-17754800' },
        { text: '992-17754774', value: '992-17754774' },
        { text: '992-17754811', value: '992-17754800' },
      ],

    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
};
</script>
